export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["こんにちは i18n !!"])},
  "main-visual": {
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ターゲット"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マーケット"])},
    "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日本"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ラテン‧アメリカ"])}
  },
  "userFrontEnd": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フロントエンド"])},
    "list": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマイズ・デザイン"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["豊富なおすすめデザインテンプレートを用意"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プレイヤー様向けサイトの「最適化（オプティマイズ）」実行"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML 5, レスポンシブウェブデザイン多言語対応"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8000+ 人気のあるゲーム"])}
    ],
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐデザインテンプレートを表示"])}
  },
  "boi": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["バックエンドインターフェース"])},
    "text": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザー様のニーズに応える、使いやすいインターフェイスの提供。"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャンペーン・支払い（精算）・トランザクション・CMS（コンテンツ管理システム）など"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイト内のパラメーターの設定が可能。"])}
    ],
    "list": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成果報告書"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客管理"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資金管理"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲーム管理"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロモーション管理"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンテンツ管理"])}
    ]
  },
  "advantage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メリット?"])},
    "content": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データベース"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビジネスをリアルタイムでモニタリングすることにより、常に最新の運営情報を獲得"])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データ分析"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["運営分析報告書"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利益分析報告書"])}
        ]
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24時間365日サポート"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["質問や疑問、トラブルなどいつでも問い合わせに対応"])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技術サポート"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カスタマーサービス"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
        ]
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["専門チーム"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["専属のテクニカルチームが解決に向けて全力で対応"])},
        "list": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マンツーマンのサービス"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技術開発部門"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設計チーム"])}
        ]
      }
    ]
  },
  "gameProviders": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ゲーム"])}
  },
  "payments": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済方法"])}
  },
  "process": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロセス"])},
    "content": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コンサルティング →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ヒアリングや調整を重ね、企画を立案致します。"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約締結 →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細についてご納得いただいた上で、契約書の締結を行います。"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設計 →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テンプレートからご希望のデザインを選択していただきます。"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイト作成 →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デザイン確認後、サイトの作成に着手します。"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修正作業 →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ご納得いただくまで、修正作業を行います。"])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイトオープン →"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["いよいよ、サイトのオープンです。"])}
      }
    ]
  },
  "partner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提携パートナー"])},
    "content": {
      "yc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 最大の魅力は対応力"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 各種仮想通貨対応"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 話題の最新、人気ゲームを提供"])}
      ],
      "wc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 安全で、信頼できる"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 登録簡単、使用しやすい"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- マルチデバイス対応"])}
      ],
      "mc": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 良いユーザーエクスペリエンス"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 便利な出金・入金方法"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 人気プロモーション"])}
      ]
    }
  },
  "googleForm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ"])}
  }
}
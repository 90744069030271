import { createApp } from "vue";
import { createPinia } from "pinia";
import "bootstrap";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import i18n from "./i18n";

createApp(App).use(i18n).use(router).use(createPinia()).mount("#app");

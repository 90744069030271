<template>
  <router-view />
</template>

<script>
import { useI18n } from "vue-i18n";
import useLocale from "@/composables/useLocale";

export default {
  setup() {
    const { t, availableLocales } = useI18n();

    const { initLocale } = useLocale();
    initLocale();

    return {
      t,
      availableLocales,
    };
  },
};
</script>

<style lang="scss">
@import "assets/scss/all";
</style>

import { useI18n } from "vue-i18n";
import Cookies from "js-cookie";

export default function () {
  const { locale, availableLocales } = useI18n();

  function initLocale() {
    if (!Cookies.get("locale")) {
      // get browser default language
      const { 0: browserLocale } = navigator.language.split("-");
      switchLocale(browserLocale);
    }
  }
  function switchLocale(newLocale) {
    const findLocale = availableLocales.find(
      (availableLocale) => newLocale === availableLocale
    );
    if (!findLocale) {
      console.error(`${newLocale} locale is not supported`);
    }
    locale.value = findLocale
      ? newLocale
      : process.env.VUE_APP_I18N_LOCALE || "ja";
    Cookies.set("locale", locale.value, { expires: 30 });
  }

  return {
    initLocale,
    switchLocale,
  };
}
